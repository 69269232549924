<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-05-25 22:59:33
 * @LastEditTime: 2021-05-27 14:54:30
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="company-new">
    <SonTitle title="企业动态"></SonTitle>
    <NewsCard
      v-for="item in list"
      :key="item.id"
      :info="item"
      source="News"
    ></NewsCard>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: []
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      let params = {
        type: 2
      }
      this.$api.getNewsList(params).then((res) => {
        this.list = res.data.data
      })
    }
  },
}
</script>

<style scoped lang='scss'>
</style>